// Helper function to get a query parameter from the URL
const getQueryParam = (param) => new URLSearchParams(window.location.search).get(param)

// Helper function to clear all URL parameters
const clearUrlParams = () => window.history.replaceState(null, '', window.location.pathname)

// Helper function to update hrefs of multiple links with the query parameters
const updateLinksWithQuery = (links, urlParams) => {
    links.forEach(link => {
        const baseUrl = link.getAttribute('href')
        link.href = `${baseUrl}?${urlParams.toString()}`
    })
}

// Process the result list and update links with the query parameters
const processResultList = (resultList, urlParams) => {
    resultList.querySelectorAll('li').forEach((list) => {
        const titleLink = list.querySelector('h3 > a')
        const listLink = list.querySelector('p > a')
        titleLink && listLink && updateLinksWithQuery([titleLink, listLink], urlParams)
    })
}

// Opens the corresponding tab for a given collapse box element
const openTabForCollapseBox = (element) => {
    const tabPaneParent = element.closest('.tab-pane')
    const navTabsParent = tabPaneParent ? tabPaneParent.parentElement.previousElementSibling : null
    if (navTabsParent) {
        const correspondingNavLink = navTabsParent.querySelector(`.nav-link[aria-controls="${tabPaneParent.id}"]`)
        correspondingNavLink && correspondingNavLink.click()
    }
}

// Opens a collapse element and updates the URL hash
const openCollapseElement = (element) => {
    const ariaLabelledBy = element.getAttribute('aria-labelledby')

    const aHrefEl = ariaLabelledBy
        ? document.querySelector(`.collapse-box header#${ariaLabelledBy}`)
        : element
    aHrefEl && aHrefEl.click()
    location.hash = aHrefEl.querySelector('a').getAttribute('href')

    // Open the corresponding tab if the element is inside a tab-pane
    openTabForCollapseBox(element)

    // Smoothly scroll to the opened element
    element.scrollIntoView({ behavior: 'smooth', block: 'start' })
}

// Processes all collapse boxes and opens the relevant one based on the query words
const processCollapseBoxes = (collapseBoxes, queryWords) => {
    const box = [...collapseBoxes].find((element) => {
        const textContent = element.textContent.toLowerCase()
        const found = queryWords.some(word => textContent.includes(word))
        return !!found
    })

    if (box) {
        new URLSearchParams(window.location.search).size && clearUrlParams()
        openCollapseElement(box)
    }
}

// Main function to handle search results
const searchResult = () => {
    const queryParam = getQueryParam('q')
    if (!queryParam) return

    // Check if results list is present
    const resultList = document.querySelector('.results-list')
    if (resultList) {
        processResultList(resultList, new URLSearchParams(window.location.search))
        return // Stop execution if results list is processed
    }

    // If no results list, check for collapse boxes
    const collapseBoxes = document.querySelectorAll('.collapse-box > *')
    if (collapseBoxes.length > 0) {
        const queryWords = queryParam.toLowerCase().split(' ')
        processCollapseBoxes(collapseBoxes, queryWords)
    }
}

// Opens the corresponding tab for the currently opened collapse box
const OpenTabsByHash = (collapse) => {
    const openedHeader = collapse.querySelector('header[aria-expanded="true"]')
    openedHeader && openTabForCollapseBox(openedHeader)
}

// Opens a collapse item if the URL hash matches its href
const collapseOpenItemWhenFragment = () => {
    const node = document.querySelector(`.collapse-box header[id^='trigger'] a[href='${window.location.hash}']`)
    node && node.click()
}

// Main function to handle collapse boxes and URL hash navigation
const openAccordingCollapse = () => {
    const collapses = document.querySelectorAll('.collapse-box')
    const hasHash = !!window.location.hash
    const queryParam = getQueryParam('q')

    // Process search results if query parameter is present
    if (queryParam) searchResult()

    collapses.forEach(collapse => {
        const triggerOpenHeader = collapse.querySelector('header[id*="open"]')
        const openAside = collapse.querySelector('aside[id*="open"]')

        // Open the first collapse if no hash or queryParam is present
        if (!hasHash && !queryParam && triggerOpenHeader && openAside) {
            triggerOpenHeader.classList.remove('collapsed')
            triggerOpenHeader.setAttribute('aria-expanded', 'true')
            openAside.classList.add('collapse', 'show')
        }

        // Handle URL hash navigation
        if (hasHash) {
            collapseOpenItemWhenFragment()
            OpenTabsByHash(collapse)
        }

        // If don't both hash and query Param are present, open the first tab
        if (!hasHash && !queryParam) {
            const buttons = document.querySelectorAll('.nav.nav-tabs .nav-item button')
            buttons && buttons[0].click()
        }
    })
}

// Adds name attribute to items and handles URL updates on click
const collapseAddNameToItems = () => {
    document.querySelectorAll(".collapse-box header[id^='trigger'] a").forEach((elem) => {
        if (!elem.hasAttribute('name')) {
            // Add name attribute to the element
            elem.setAttribute('name', elem.getAttribute('href').substring(1))

            // Handle click event to update URL hash
            elem.addEventListener('click', (event) => {
                event.preventDefault()
                history.pushState ?
                    history.pushState(null, null, event.target.getAttribute('href')) :
                    location.hash = event.target.getAttribute('href')

                // Clear URL parameters if present
                new URLSearchParams(window.location.search).size & clearUrlParams()
            })
        }
    })
}

// Export functions for use in other modules
export { openAccordingCollapse, collapseAddNameToItems, collapseOpenItemWhenFragment }
